import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { withPage } from '../Page';
import ActionCreator from '../ActionCreator';
import Selectors from '../Selectors';
import * as Widget from '../Components/Widget';
import OrderDetailContent from '../Components/OrderDetailContent';
import PathPrefix from '../Utils/PathPrefixUtil';
import * as L from '../Utils/Lang';
import AppConfig from '../../src/Config';

class OrderDetailPage extends React.Component {
  render() {
    let { location, profile, order } = this.props;
    if (!profile && AppConfig.favor === 'tel25') {
      return (
        <Widget.Center extraCss="margin-top: 100px; margin-bottom: 84px;">
          <div style={{ width: 100, height: 100 }}>
            <Widget.BgImage
              extraCss="background-color: inherit;"
              size="contain"
              image={PathPrefix(`/images/order_record/icon-error.png`)}
            />
          </div>
          <div style={{ color: '#F84343', marginTop: 20 }}>
            {L.s('no-order-or-no-right-access-order')}
          </div>
        </Widget.Center>
      );
    }

    return (
      <Wrapper spStyle={AppConfig.favor === "tel25-sp"}>
        {order && !order.are_goods_shipped && (
          <Info>
            <p>親愛的顧客您好，</p>
            <p>{this._getOrderMessage()}</p>
            {
              AppConfig.favor != "tel25-sp"
              && <>
                  <p>
                    <span>客服line:</span>
                    <a href="http://line.me/ti/p/@tel25" target="_blank">@tel25</a>
                  </p>
                  <p>
                    <span>客服電話:</span>
                    <a href="tel:+88625211005" target="_blank">+88625211005</a>
                  </p>
                 </>
            }
          </Info>
        )}

        <OrderDetailContent
          key={location.search}
          extraCss="margin: 0 auto;"
          location={location}
          order={order}
        />
      </Wrapper>
    );
  }

  _getOrderMessage = () => {
    let { order } = this.props;
    if (order.is_sp_order) {
      if (order.payment_type === 'ecpay_cvs') {
        return ('GlobalStar感謝您的訂購，已經收到您貨到付款訂單，我們將立即進行商品的出貨流程，並送至您指定的門市。商品到店後會通知您取件，屆時再請您完成付款取貨。\n系統將會於15個工作天內將電子發票寄送至您所填寫的E-MAIL信箱，如未如期收到，再請您與我們聯繫。')
      } else if (order.payment_type === 'ecpay_cvpay') {
        return (
          <>
            <p>GlobalStar感謝您的訂購，已經收到您超商代碼繳費訂單，請點擊下方“前往獲得超商代碼”，至綠界頁面取得超商代碼。待您付款完成後，我們將立即進行商品的出貨流程，並送至您指定的門市。商品到店後會通知您取件，屆時再請您至門市取貨</p>
            <a href="https://www.ecpay.com.tw/Market/Howto#howpay">超商代收付介紹</a>
            <p>系統將會於15個工作天內將電子發票寄送至您所填寫的E-MAIL信箱，如未如期收到，再請您與我們聯繫。</p>
          </>
        )
      } else {
        return 'GlobalStar感謝您的訂購，已經收到您線上刷卡訂單，請點擊下方前往結帳，至綠界頁面進行付款。付款完成後，我們將立即進行商品的出貨流程，並送至您指定的門市。商品到店後會通知您取件，屆時再請您至門市取貨。\n系統將會於15個工作天內將電子發票寄送至您所填寫的E-MAIL信箱，如未如期收到，再請您與我們聯繫。';
      }
    } else {
      if (order.payment_type === 'ecpay_cvs') {
        return ('TEL25感謝您的訂購，我們已經收到您的貨到付款訂單，TEL25將立即進行商品的備貨與出貨流程，並出貨到您指定的收件門市。商品到店後會通知您取件，屆時再請您完成付款取貨。\n系統會於取貨後10個工作天內寄送電子發票至您填寫的E-Mail信箱。(如未如期收到，再請您與我們聯繫。）')
      } else if (
        order.payment_type === 'ecpay' &&
        order.delivery_location_type === 'airport'
      ) {
        return '我們已經收到您的訂單與付款資訊，請您於出國當天，到您選擇的取件的機場櫃台，出示此畫面，完成取件。\n提醒您，卡片插入手機後即開通，請您務必開啟「行動數據」與「數據漫遊」，即可使用網路。\n系統會於取貨後10個工作天內寄送電子發票至您填寫的E-Mail信箱。(如未如期收到，再請您與我們聯繫。）\n\nTEL25 感謝您的訂購';
      } else if (order.payment_type === 'ecpay_cvpay') {
        return (
          <>
            <p>您的訂單已成立，請將「超商繳款代碼」記下並前往四大超商多媒體事務機操作列印。</p>
            <a href="https://www.ecpay.com.tw/Market/Howto#howpay">超商代收付介紹</a>
          </>
        )
      } else {
        return 'TEL25感謝您的訂購，我們已經收到你的訂單與付款資訊，請點選下方“前往結帳”進行線上結帳。\n付款完成後，TEL25將立即進行商品的備貨與出貨流程，請您耐心等候。\n系統會於取貨後10個工作天內寄送電子發票至您填寫的E-Mail信箱。(如未如期收到，再請您與我們聯繫。）';
      }
    }

  };
}

let Wrapper = styled.div`
  flex-grow: 1;
  padding: 30px 20px 50px;
  background-color: ${props => props.spStyle ? "#000" : "white"};
  background-image:
    ${props => props.spStyle &&
    "radial-gradient(circle farthest-corner at 50% 50%, hsla(0, 0%, 100%, .05), hsla(0, 0%, 100%, .19))"};
`;

const Info = styled.div`
  max-width: 780px;
  margin: 0 auto 40px;
  border: 4px solid #3f8dcf;
  padding: 37px 84px;
  background: #ffffff;
  box-shadow: 10px -10px 0 0 #3f8dcf;
  color: #3f8dcf;
  font-size: 16px;
  font-weight: 600;

  & > p {
    white-space: pre-wrap;
    text-align: justify;

    & > span {
      margin-right: 5px;
      font-size: inherit;
      color: inherit;
    }

    & > a {
      font-size: inherit;
      color: inherit;
    }
  }

  @media screen and (max-width: 820px) {
    max-width: 648px;
    padding: 37px 40px;
  }
`;

export default withPage(
  connect(
    (state, ownProps) => ({
      profile: Selectors.getLoginUser(state),
      order: Selectors.getOrder(state, Selectors.getQueryParams(ownProps).id),
    }),
    ActionCreator
  )(OrderDetailPage)
);
